import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/lab/Alert";

import axios from "axios";
import apiConfig from "../api/apiConfig";

const ManualOrder = (props) => {
	const [searchString, setSearchString] = useState();
	const [searchResult, setSearchResult] = useState();

	const [searchSelected, setSearchSelected] = useState();

	const [selectedToOrder, setSelectedToOrder] = useState([]);

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarSeverity, setSnackBarSeverity] = useState("");
	const [snackBarMessage, setSnackBarMessage] = useState("");

	const handleSearchChange = (e) => {
		setSearchString(e.target.value);
	};

	const handleSearchClear = () => {
		setSearchString("");
	};

	const handleSearchCall = () => {
		const headers = {
			"Api-Key": "inQScFac5we8",
		};

		const request = {
			text: searchString,
		};

		console.log(apiConfig["search"], request);

		axios
			.post(apiConfig["search"], request, {
				headers: headers,
			})
			.then((result) => {
				console.log(result);
				if (result.data.data.matches) {
					console.log(typeof result.data.data.matches);

					if (Array.isArray(result.data.data.matches)) {
						console.log("matches exist");
						let items = result.data.data.matches;

						console.log(items);

						// let valuesArray = Object.values(items[0].text);
						// console.log("all values: ", valuesArray);

						setSearchResult(items);
					} else {
						console.log("not passed");
						setSnackBarMessage("Please give at least 3 parameters to search");
						setSnackBarSeverity("warning");
						setSnackBarOpen(true);
						return;
					}
				} else {
				}
			})
			.catch((error) => {
				console.log(error);
				setSnackBarMessage("Request or server error: " + error);
				setSnackBarSeverity("error");
				setSnackBarOpen(true);
			});
	};

	const selectSearchResult = (selected) => {
		setSearchSelected(selected);
	};

	const handleAddToOrder = (i) => {
		console.log(i);
		let exist = false;

		selectedToOrder.forEach((element) => {
			if (element.index == searchResult[i].index) {
				exist = true;
			}
		});

		if (!exist) {
			let tempObject = searchResult[i];
			tempObject.quantity = 0;
			setSelectedToOrder((prevSelectedToOrder) => [
				...prevSelectedToOrder,
				tempObject,
			]);
		}
	};

	const handleOrderQuantityChange = (e) => {
		let itemId = e.target.name;
		let tempArray = selectedToOrder;

		tempArray.map((item) => {
			if (item.index == itemId) {
				item.quantity = parseInt(e.target.value);
			}
		});

		setSelectedToOrder(tempArray);
	};

	const handleRemoveItemFromOrder = (itemId) => {
		let tempArray = [];

		selectedToOrder.map((item) => {
			if (item.index != itemId) {
				tempArray.push(item);
			}
		});

		console.log(tempArray);

		setSelectedToOrder(tempArray);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarOpen(false);
	};

	return (
		<>
			<div style={{ display: props.display ? "block" : "none" }}>
				<div className="header-wrapper">
					<div className="manual-search-box-wrapper">
						<TextField
							id="outlined-basic"
							label="Search"
							variant="outlined"
							onChange={handleSearchChange}
							value={searchString}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment>
										<IconButton onClick={handleSearchCall}>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						{searchString ? (
							<IconButton color="primary" onClick={handleSearchClear}>
								<ClearIcon />
							</IconButton>
						) : (
							""
						)}
					</div>
				</div>
				<div className="processed-body-wrapper row">
					{searchResult ? (
						<div className="manual-body-column manual-body-column-result col-md-6">
							<h3 className="manual-body-result-header">Search results</h3>
							<div className="manual-body-result-box">
								{searchResult
									? searchResult.map((item, index) => {
											let string = "";
											return (
												<>
													<div className="manual-body-result-item-wrapper">
														<div
															className={`add-item-list-item-wrapper ${
																searchSelected - 1 == index ? "selected" : ""
															}`}
															onClick={() => selectSearchResult(index + 1)}
														>
															<span className="ir-article-nubmer-inline">
																[{item.text.Artikelnummer}]
															</span>
															<span className="ir-article-index">
																[{item.index}]
															</span>
															{
																(string += Object.values(item.text)
																	.map((entrie, i) => {
																		if (
																			entrie != "nan" &&
																			i !=
																				Object.keys(item.text).indexOf(
																					"Artikelnummer"
																				) &&
																			i !=
																				Object.keys(item.text).indexOf(
																					"USER_ArtNrTeil1"
																				) &&
																			i !=
																				Object.keys(item.text).indexOf(
																					"USER_ArtNrTeil2"
																				)
																		) {
																			return " " + entrie + ",";
																		}
																	})
																	.join(""))
																// item.text
															}
														</div>
														<div className="manual-order-item-actions-wrapper">
															<Tooltip title="Add to order">
																<KeyboardDoubleArrowRightIcon
																	className="manual-body-result-item-add-icon"
																	onClick={() => handleAddToOrder(index)}
																/>
															</Tooltip>
														</div>
													</div>
												</>
											);
									  })
									: ""}
							</div>
						</div>
					) : (
						""
					)}
					{searchResult ? (
						<div className="manual-body-column manual-body-column-order col-md-6">
							<div className="manual-body-order-box">
								<h3 className="manual-body-order-header">New order</h3>
								{selectedToOrder && selectedToOrder.length != 0
									? selectedToOrder.map((item, index) => {
											let string = "";
											return (
												<>
													<div className="manual-body-order-item-wrapper-box">
														<div className="add-item-list-item-wrapper manual-body-order-item-wrapper">
															<TextField
																className="manual-body-roder-item-quantity-input"
																variant="outlined"
																type="number"
																defaultValue={item.quantity}
																name={item.index}
																onChange={handleOrderQuantityChange}
															/>
															<div className="manual-body-order-item-text">
																{
																	(string +=
																		`[${item.index}] ` +
																		Object.values(item.text).map((entrie) => {
																			return " " + entrie;
																		}))
																}
															</div>
															<Tooltip title="Remove from order">
																<ClearIcon
																	className="manual-body-order-item-remove-icon"
																	onClick={() =>
																		handleRemoveItemFromOrder(item.index)
																	}
																/>
															</Tooltip>
														</div>
													</div>
												</>
											);
									  })
									: ""}
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
			<div>
				<Snackbar
					open={snackBarOpen}
					autoHideDuration={6000}
					onClose={handleSnackBarClose}
				>
					<MuiAlert
						onClose={handleSnackBarClose}
						severity={snackBarSeverity}
						elevation={6}
						variant="filled"
					>
						{snackBarMessage}
					</MuiAlert>
				</Snackbar>
			</div>
		</>
	);
};

export default ManualOrder;
