import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ReorderIcon from "@mui/icons-material/Reorder";
import SearchIcon from "@mui/icons-material/Search";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import axios from "axios";
import apiConfig from "../api/apiConfig";

import { useRecoilState } from "recoil";
import { loadingAtom } from "../atoms/atoms";

import dataSelected from "../dump/selectedMultiMatches.json";

const WizardResolveMultipleMatches = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const [dataItems, setDataItems] = useState(null);

	const [expandedRow, setExpandedRow] = useState(null);
	const [expandedView, setExpandedView] = useState("matches");

	const [searchString, setSearchString] = useState(null);
	const [searchResult, setSearchResult] = useState(null);
	const [searchItemIndex, setSearchItemIndex] = useState(null);

	const [allFilled, setAllFilled] = useState(null);

	useEffect(() => {
		formDataForTable();
	}, []);

	const formDataForTable = () => {
		console.log(props.data);
		if (props.data) {
			let multipleMatchesArray = [];

			if (props.data.msg_matches.length > 1) {
				props.data.msg_matches.forEach((match, i) => {
					if (match.values.length > 1 || match.values.length == 0) {
						match.details.index = i;
						match.item = props.data.msg_items[i];
						multipleMatchesArray.push(match);
					}
				});
			}

			if (props.data.attachment_matches.length > 1) {
				props.data.attachment_matches.forEach((attachmentMatch, i) => {
					if (
						attachmentMatch.values.length > 1 ||
						attachmentMatch.values.length == 0
					) {
						attachmentMatch.details.index = i;
						attachmentMatch.item = props.data.msg_items[i];
						multipleMatchesArray.push(attachmentMatch);
					}
				});
			}

			let tempItems = [];

			multipleMatchesArray.forEach((multiMatch, i) => {
				let textArray = [];
				tempItems.push(multiMatch);

				multiMatch.values.forEach((multiMatchValue) => {
					textArray.push(multiMatchValue.text);
				});

				tempItems[i].matches = textArray;

				// multiMatch.values = textArray;
			});

			setDataItems(tempItems);
			console.log(tempItems);
		}
	};

	const expandItemMatches = (index) => {
		setExpandedRow(index);
		setExpandedView("matches");
	};

	const expandItemSearch = (index) => {
		let tempString = dataItems.find((item) => {
			return item.details.index == index;
		}).item;
		setExpandedRow(index);
		setSearchString(tempString);
		setExpandedView("search");
	};

	const handleSearchChange = (e) => {
		setSearchString(e.target.value);
	};

	const handleSearchClear = () => {
		setSearchString("");
	};

	const handleSearchCall = (itemIndex) => {
		setLoading(true);
		const headers = {
			"Api-Key": "inQScFac5we8",
		};

		const request = {
			text: searchString,
		};

		console.log(apiConfig["search"], request);

		axios
			.post(apiConfig["search"], request, {
				headers: headers,
			})
			.then((result) => {
				console.log(result);
				if (result.data.data.matches) {
					console.log(typeof result.data.data.matches);

					if (Array.isArray(result.data.data.matches)) {
						console.log("matches exist");
						let items = result.data.data.matches;

						console.log(items);

						// let valuesArray = Object.values(items[0].text);
						// console.log("all values: ", valuesArray);
						setSearchItemIndex(itemIndex);
						setSearchResult(items);
						setLoading(false);
					} else {
						console.log("not passed");
						// setSnackBarMessage("Please give at least 3 parameters to search");
						// setSnackBarSeverity("warning");
						// setSnackBarOpen(true);
						setLoading(false);
						return;
					}
				} else {
				}
			})
			.catch((error) => {
				console.log(error);
				// setSnackBarMessage("Request or server error: " + error);
				// setSnackBarSeverity("error");
				// setSnackBarOpen(true);
			});
	};

	const selectItemHandler = (item, index) => {
		const tempData = dataItems.map((dataItem) => {
			if (dataItem.details.index == index) {
				const values = Object.values(item);
				const filteredValues = values.filter((value) => value !== "nan");

				return {
					...dataItem,
					selected: {
						text: filteredValues.join(" "),
						Artikelnummer: item.Artikelnummer,
						value: item,
					},
				};
			}
			return dataItem;
		});

		checkAllSelected(index, "select");
		setDataItems(tempData);
	};

	const removeSelected = (index) => {
		const tempData = dataItems.map((dataItem) => {
			if (dataItem.details.index == index) {
				return {
					...dataItem,
					selected: null,
				};
			}
			return dataItem;
		});

		checkAllSelected(index, "remove");

		setDataItems(tempData);
	};

	const processOrder = () => {
		console.log("process order");
		props.callback(dataItems);
	};

	const checkAllSelected = (itemChanged, operant) => {
		let allFilledFlag = true;

		if (operant == "remove") {
			allFilledFlag = false;
		} else {
			dataItems.forEach((item) => {
				if (!item.selected && itemChanged != item.details.index) {
					allFilledFlag = false;
				}
			});
		}

		if (allFilledFlag) {
			setAllFilled(true);
		} else {
			setAllFilled(false);
		}
	};

	const cheatHandler = () => {
		props.callback(dataSelected);
	};

	return (
		<>
			<div className="process-column-header-with-actions">
				<h4>Please process all the multiple matches to procceed.</h4>
				<Button
					variant="contained"
					color="success"
					onClick={processOrder}
					disabled={!allFilled}
				>
					Process order
				</Button>
				{/* <Button onClick={cheatHandler}>Cheat</Button> */}
			</div>
			<p>You can choose from the list or correct the item search</p>
			<div className="row">
				<div className="processed-column">
					{dataItems ? (
						<div className="processed-column-items-wrapper">
							{dataItems.map((item) => {
								return (
									<div className="processed-column-items-item">
										<div className="process-column-item-initial">
											<LabelImportantIcon />

											{"[" + item.details.index + "]" + " " + item.item}
										</div>
										{item.selected ? (
											<div className="process-column-item-selected">
												<DoneAllIcon />
												<IconButton
													onClick={() => removeSelected(item.details.index)}
												>
													<ClearIcon />
												</IconButton>
												{item.selected ? item.selected.text : ""}
											</div>
										) : (
											""
										)}
										<div className="process-column-item-actions">
											<span className="process-column-item-actions-count">
												{item.values.length} matches
											</span>
											<Stack direction="row" spacing={2}>
												<Button
													startIcon={<ReorderIcon />}
													onClick={() => expandItemMatches(item.details.index)}
												>
													Review Matches
												</Button>
												<Button
													startIcon={<SearchIcon />}
													onClick={() => expandItemSearch(item.details.index)}
												>
													Search
												</Button>
											</Stack>
										</div>
										<div
											className="process-column-item-body-search"
											hidden={
												item.details.index == expandedRow &&
												expandedView == "search"
													? false
													: true
											}
										>
											<div className="manual-search-box-wrapper">
												<TextField
													id="outlined-basic"
													label="Search"
													variant="outlined"
													onChange={handleSearchChange}
													value={searchString}
													fullWidth
													multiline
													InputProps={{
														endAdornment: (
															<InputAdornment>
																<IconButton
																	onClick={() =>
																		handleSearchCall(item.details.index)
																	}
																>
																	<SearchIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
												{searchString ? (
													<IconButton
														color="primary"
														onClick={handleSearchClear}
													>
														<ClearIcon />
													</IconButton>
												) : (
													""
												)}
											</div>
											<TableContainer component={Paper} sx={{ maxHeight: 440 }}>
												<Table
													sx={{ minWidth: 650 }}
													size="small"
													aria-label="a dense table"
													stickyHeader
												>
													{searchResult &&
													searchItemIndex == item.details.index ? (
														<TableHead>
															<TableRow>
																<TableCell>Actions</TableCell>
																<TableCell>Artikelnummer</TableCell>
																<TableCell align="right">
																	Bezeichnung1
																</TableCell>
																<TableCell align="right">
																	Bezeichnung2
																</TableCell>
																<TableCell align="right">Matchcode</TableCell>
																<TableCell align="right">
																	Specials_dims
																</TableCell>
																<TableCell align="right">
																	USER_ANSIOD1
																</TableCell>
																<TableCell align="right">
																	USER_ANSIOD2
																</TableCell>
																<TableCell align="right">
																	USER_ANSIWD1
																</TableCell>
																<TableCell align="right">
																	USER_ANSIWD2
																</TableCell>
																<TableCell align="right">
																	USER_ArtNrTeil1
																</TableCell>
																<TableCell align="right">
																	USER_ArtNrTeil2
																</TableCell>
																<TableCell align="right">USER_Attest</TableCell>
																<TableCell align="right">
																	USER_Ausfuehrung
																</TableCell>
																<TableCell align="right">USER_ENOD1</TableCell>
																<TableCell align="right">USER_ENOD2</TableCell>
																<TableCell align="right">USER_ENWD1</TableCell>
																<TableCell align="right">USER_ENWD2</TableCell>
																<TableCell align="right">
																	USER_Produktgruppe
																</TableCell>
																<TableCell align="right">
																	USER_TechnLV
																</TableCell>
																<TableCell align="right">
																	USER_Werkstoff
																</TableCell>
															</TableRow>
														</TableHead>
													) : (
														""
													)}
													<TableBody>
														{searchResult &&
														searchItemIndex == item.details.index
															? searchResult.map((row) => (
																	<TableRow
																		key={row.text.Artikelnummer}
																		sx={{
																			"&:last-child td, &:last-child th": {
																				border: 0,
																			},
																		}}
																	>
																		<TableCell>
																			<IconButton
																				aria-label="select"
																				className="process-column-item-body-select"
																				onClick={() =>
																					selectItemHandler(
																						row.text,
																						item.details.index
																					)
																				}
																				disabled={
																					item.selected
																						? row.text.Artikelnummer ==
																						  item.selected.Artikelnummer
																						: false
																				}
																			>
																				<TaskAltIcon />
																			</IconButton>
																		</TableCell>
																		<TableCell
																			component="th"
																			scope="row"
																			className={
																				item.selected &&
																				row.text.Artikelnummer ==
																					item.selected.Artikelnummer
																					? "process-column-item-body-select-selected"
																					: ""
																			}
																		>
																			{row.text.Artikelnummer}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.Bezeichnung1}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.Bezeichnung2}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.Matchcode}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.Specials_dims}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ANSIOD1}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ANSIOD2}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ANSIWD1}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ANSIWD2}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ArtNrTeil1}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ArtNrTeil2}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_Attest}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_Ausfuehrung}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ENOD1}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ENOD2}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ENWD1}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_ENWD2}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_Produktgruppe}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_TechnLV}
																		</TableCell>
																		<TableCell align="right">
																			{row.text.USER_Werkstoff}
																		</TableCell>
																	</TableRow>
															  ))
															: ""}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
										<div
											className="process-column-item-body"
											hidden={
												item.details.index == expandedRow &&
												expandedView == "matches"
													? false
													: true
											}
										>
											<TableContainer component={Paper} sx={{ maxHeight: 440 }}>
												<Table
													sx={{ minWidth: 650 }}
													size="small"
													aria-label="a dense table"
													stickyHeader
												>
													<TableHead>
														<TableRow>
															<TableCell>Actions</TableCell>
															<TableCell>Artikelnummer</TableCell>
															<TableCell align="right">Bezeichnung1</TableCell>
															<TableCell align="right">Bezeichnung2</TableCell>
															<TableCell align="right">Matchcode</TableCell>
															<TableCell align="right">Specials_dims</TableCell>
															<TableCell align="right">USER_ANSIOD1</TableCell>
															<TableCell align="right">USER_ANSIOD2</TableCell>
															<TableCell align="right">USER_ANSIWD1</TableCell>
															<TableCell align="right">USER_ANSIWD2</TableCell>
															<TableCell align="right">
																USER_ArtNrTeil1
															</TableCell>
															<TableCell align="right">
																USER_ArtNrTeil2
															</TableCell>
															<TableCell align="right">USER_Attest</TableCell>
															<TableCell align="right">
																USER_Ausfuehrung
															</TableCell>
															<TableCell align="right">USER_ENOD1</TableCell>
															<TableCell align="right">USER_ENOD2</TableCell>
															<TableCell align="right">USER_ENWD1</TableCell>
															<TableCell align="right">USER_ENWD2</TableCell>
															<TableCell align="right">
																USER_Produktgruppe
															</TableCell>
															<TableCell align="right">USER_TechnLV</TableCell>
															<TableCell align="right">
																USER_Werkstoff
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{item.matches.map((row) => (
															<TableRow
																key={row.Artikelnummer}
																sx={{
																	"&:last-child td, &:last-child th": {
																		border: 0,
																	},
																}}
															>
																<TableCell>
																	<IconButton
																		aria-label="select"
																		className="process-column-item-body-select"
																		onClick={() =>
																			selectItemHandler(row, item.details.index)
																		}
																		disabled={
																			item.selected
																				? row.Artikelnummer ==
																				  item.selected.Artikelnummer
																				: false
																		}
																	>
																		<TaskAltIcon />
																	</IconButton>
																</TableCell>
																<TableCell
																	component="th"
																	scope="row"
																	className={
																		item.selected &&
																		row.Artikelnummer ==
																			item.selected.Artikelnummer
																			? "process-column-item-body-select-selected"
																			: ""
																	}
																>
																	{row.Artikelnummer}
																</TableCell>
																<TableCell align="right">
																	{row.Bezeichnung1}
																</TableCell>
																<TableCell align="right">
																	{row.Bezeichnung2}
																</TableCell>
																<TableCell align="right">
																	{row.Matchcode}
																</TableCell>
																<TableCell align="right">
																	{row.Specials_dims}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ANSIOD1}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ANSIOD2}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ANSIWD1}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ANSIWD2}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ArtNrTeil1}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ArtNrTeil2}
																</TableCell>
																<TableCell align="right">
																	{row.USER_Attest}
																</TableCell>
																<TableCell align="right">
																	{row.USER_Ausfuehrung}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ENOD1}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ENOD2}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ENWD1}
																</TableCell>
																<TableCell align="right">
																	{row.USER_ENWD2}
																</TableCell>
																<TableCell align="right">
																	{row.USER_Produktgruppe}
																</TableCell>
																<TableCell align="right">
																	{row.USER_TechnLV}
																</TableCell>
																<TableCell align="right">
																	{row.USER_Werkstoff}
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						""
					)}
				</div>
				{/* <div className="col-md-6 processed-column">2</div> */}
			</div>
		</>
	);
};

export default WizardResolveMultipleMatches;
