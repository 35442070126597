//const host = "api";

// const host =
// 	process.env.NODE_ENV === "production"
//  		? process.env.REACT_APP_PROD_API_URL
//  		: process.env.REACT_APP_DEV_API_URL;

const host = "https://demo-mangusta.xyz";

// const host = "http://ec2-3-75-102-23.eu-central-1.compute.amazonaws.com:5000";

// const host = "https://flamingo-api.herokuapp.com";

const apiConfig = {
	key: "inqReqMangusta",
	Main: `${host}`,
	process: `${host}/process`,
	search: `${host}/search`,
};

export default apiConfig;
