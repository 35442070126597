import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home";
import { RecoilRoot } from "recoil";
import Loader from "./components/Loader";

function App() {
	return (
		<>
			<RecoilRoot>
				<Home />
				<Loader />
			</RecoilRoot>
		</>
	);
}

export default App;
