import React, { useState } from "react";
import UploadFile from "./UploadFile";
import Button from "@mui/material/Button";
import ManualOrder from "./ManualOrder";
import UploadWizard from "./UploadWizard";

const Home = (props) => {
	const [view, setView] = useState("upload");

	const handleViewChange = (view) => {
		setView(view);
	};

	return (
		<>
			<div className="select-view-btn-wrapper">
				<Button
					className="select-view-btn-btn"
					size="large"
					variant="contained"
					onClick={() => handleViewChange("upload")}
					disabled={view == "upload" ? true : false}
				>
					Upload file
				</Button>
				<Button
					className="select-view-btn-btn"
					size="large"
					variant="contained"
					onClick={() => handleViewChange("manual")}
					disabled={view == "manual" ? true : false}
				>
					Manual entry
				</Button>
			</div>
			{/* <UploadFile display={view == "upload"} /> */}
			<UploadWizard display={view == "upload"} />
			<ManualOrder display={view == "manual"} />
		</>
	);
};

export default Home;
